var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};

(function ($) {
  Drupal.behaviors.ctaToutV1 = {
    attach: function (context) {
      var $override = $('.js-cta-override', context);
      var $ctaTout = $('.js-cta-tout', context);

      $override.each(function () {
        $(this)
          .once()
          .on('click', function (e) {
            e.preventDefault();
            $(document).trigger($(this).data('cta-override'));
          });
      });
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        $('.js-android').hide();
      } else {
        $('.js-ios').hide();
      }
    }
  };
})(jQuery);
